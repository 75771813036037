
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useGlobalState } from 'src/hooks/use-global-state';
import withAuth from 'src/lib/with-auth';
import LoginModule from 'src/modules/login/views';
const Login = () => {
    const { actions: { authTokenLogin }, } = useGlobalState('user');
    const router = useRouter();
    useEffect(() => {
        if (router.isReady) {
            authTokenLogin();
        }
    }, [router.isReady]);
    return <LoginModule />;
};
const __Next_Translate__Page__194cb66797b__ = withAuth(Login);

    export default __appWithI18n(__Next_Translate__Page__194cb66797b__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  